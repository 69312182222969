import API from '@/apis/base'
import { authHeader } from '@/constants/auth'
import { BaseResponse } from '@/types/api.type'
import { UserTermsAgreementStatus } from '@/types/term.type'

export const getUserAgreementStatus = (accessToken: string) =>
  API.get<BaseResponse<UserTermsAgreementStatus>>('/api/v1/user/terms', {
    headers: {
      [authHeader.X_POPUP_AUTH_HEADER]: accessToken,
    },
  })

export const updateUserAgreementStatus = (params: UserTermsAgreementStatus) =>
  API.put('/api/v1/user/terms', params)
