import { create } from 'zustand'

import authAPI from '@/apis/auth.api'
import { AuthUser } from '@/types/auth.type'

interface AuthState extends AuthUser {
  isAuthUser: boolean // 로그인한 유저
  isSignedUp: boolean // 가입 완료한 유저
  setAuth: (tokens: Partial<AuthUser>) => void
  setSignedUp: (isSignedUp: boolean) => void
  removeAuth: () => void
}

const initialAuth = {
  isAuthUser: false,
  isSignedUp: false,
  accessToken: '',
  refreshToken: '',
}

const useAuthStore = create<AuthState>()((set) => ({
  ...initialAuth,
  setSignedUp: (isSignedUp) => set((state) => ({ ...state, isSignedUp })),
  setAuth: (tokens) =>
    set((state) => ({ ...state, ...tokens, isAuthUser: true })),
  removeAuth: () => {
    authAPI.logOut()
    set(() => ({ ...initialAuth }))
  },
}))

export default useAuthStore
