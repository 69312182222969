import axios from 'axios'
import * as Sentry from '@sentry/nextjs'

import authAPI from '@/apis/auth.api'
import { AuthUser } from '@/types/auth.type'
import { authHeader } from '@/constants/auth'

const PROXY_API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
})

PROXY_API.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (
      !(
        axios.isAxiosError(err) &&
        err?.config?.url === authAPI.proxyUrl.refresh &&
        err?.response?.status === 401
      )
    ) {
      Sentry.captureException(err)
    }

    return Promise.reject(err)
  },
)

export const setProxyAPIAuthHeader = (authUser: AuthUser) => {
  PROXY_API.defaults.headers[authHeader.X_POPUP_AUTH_HEADER] =
    authUser.accessToken
  PROXY_API.defaults.headers.Cookie = `${authHeader.X_POPUP_COOKIE}=${authUser.refreshToken}`
}

export default PROXY_API
