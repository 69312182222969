import { PATH } from '@/constants/path'

const buttonInfo = {
  now: {
    title: (
      <>
        NOW,
        <br />
        OPEN!
      </>
    ),
    subTitle: <>11/4-11/5 발견; the Fullest market</>,
    description: (
      <>
        마켓 발견의 근사한 공간에서
        <br />
        자연과 어우러지는 플리마켓
      </>
    ),
    href: PATH.posts + '/5',
  },
  now2: {
    title: (
      <>
        NOW,
        <br />
        OPEN!
      </>
    ),
    subTitle: <>11/11 HEY ITAEWON</>,
    description: (
      <>
        다시 돌아온
        <br />
        HEY ITAEWON
      </>
    ),
    href: PATH.posts + '/6',
  },
  prev: {
    title: (
      <>
        Previous,
        <br />
        ONOB
      </>
    ),
    subTitle: <>4/15 헤이이태원 플리마켓</>,
    description: (
      <>
        봄 기운과 함께
        <br />
        설레는 플리마켓 나들이
      </>
    ),
    href: PATH.posts + '/2',
  },
}

export default buttonInfo
