import clsx from 'clsx'

import useAuth, { PropsWithAuth } from '@/hooks/useAuth'
import { withAuthGetServerSideProps } from '@/utils/withAuth'

import BaseLayout from '@/components/Layout'
import HomeHeader from '@/components/Header/Home'
import MainCardButton, { buttonInfo } from '@/containers/MainCardButton'
import Footer from '@/components/Footer'

// if (process.env.NODE_ENV === 'development') {
//   msw()
// }

export default function Home({ auth }: PropsWithAuth) {
  useAuth(auth, { signedAccessRequired: true, loginIfNotSigned: false })

  return (
    <>
      <BaseLayout className={clsx('pt-[70px]')}>
        <HomeHeader />
        <video
          controls={false}
          width={'100%'}
          loop
          autoPlay
          muted
          playsInline
          draggable="false"
        >
          <source
            type="video/mp4"
            src="https://fleabuddy.s3.ap-northeast-2.amazonaws.com/static/videos/main_graphic_v2_market4.mp4"
          />
        </video>
        <MainCardButton {...buttonInfo.now} textRight />
        <MainCardButton {...buttonInfo.prev} />
        <Footer />
      </BaseLayout>
    </>
  )
}

export const getServerSideProps = withAuthGetServerSideProps()
