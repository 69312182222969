import clsx from 'clsx'
import { ComponentProps } from 'react'

export default function Logo({ className, ...props }: ComponentProps<'div'>) {
  return (
    <div
      className={clsx(
        'font-DespairTime',
        'font-[400]',
        'text-[39px]',
        'leading-[40px]',
        className,
      )}
      {...props}
    >
      ONOB
    </div>
  )
}
