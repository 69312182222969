import { ComponentProps, ReactNode } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

import { NextIcon } from '@/components/Icons'

interface Props extends Omit<ComponentProps<'div'>, 'title'> {
  title: ReactNode
  subTitle: ReactNode
  description: ReactNode
  textRight?: boolean
  bodyStyle?: ComponentProps<'div'>['className']
  href: string
}

export default function MainCardButton({
  title,
  subTitle,
  description,
  textRight = false,
  className,
  href,
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        'py-4',
        'px-5',
        'text-white',
        'gap-1.5',
        'border-t-[1px]',
        'border-t-white',
        'last-of-type:border-b-[1px]',
        'last-of-type:border-b-white',
        'cursor-pointer',
        className,
      )}
      {...props}
    >
      <Link href={href}>
        <div
          className={clsx(
            'flex',
            'justify-between',
            textRight && ['flex-row-reverse', 'text-end'],
          )}
        >
          <span
            className={clsx(
              'font-DespairTime',
              'font-normal',
              'text-[33px]',
              'leading-[113%]',
            )}
          >
            {title}
          </span>
          <span className={clsx('translate-y-[-30%]')}>
            <NextIcon />
          </span>
        </div>
        <div
          className={clsx('font-normal', 'text-xl', textRight && 'text-end')}
        >
          {subTitle}
        </div>
        <div
          className={clsx(
            'font-Pretendard',
            'font-light',
            'text-sm',
            'leading-[150%]',
            textRight && 'text-end',
          )}
        >
          {description}
        </div>
      </Link>
    </div>
  )
}

export { default as buttonInfo } from './buttonInfo'
