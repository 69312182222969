import clsx from 'clsx'

export default function Footer() {
  return (
    <footer
      className={clsx(
        'font-Pretendard',
        'text-gray700',
        'text-center',
        'py-[26px]',
        'px-[10px]',
        'text-[12px]',
        'font-[400]',
        'space-y-[4px]',
      )}
    >
      <div>상호 오노브 | 대표 송민형</div>
      <div>문의 010-2750-2106 | 이메일 815730@naver.com</div>
      <div className="font-[700]">
        <a
          target="_blank"
          href={process.env.NEXT_PUBLIC_BASE_URL + '/use_of_policy.html'}
          rel="noopener noreferrer"
        >
          이용약관
        </a>{' '}
        |{' '}
        <a
          target="_blank"
          href={process.env.NEXT_PUBLIC_BASE_URL + '/terms_of_privacy.html'}
          rel="noopener noreferrer"
        >
          개인정보처리방침
        </a>
      </div>
      <div>Copyright © 2023 | Right Inc. | All rights reserved</div>
    </footer>
  )
}
