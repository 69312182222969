import {
  VerifyPhoneNumberCodeReq,
  SendVerificationCodeToPhoneNumberReq,
} from '@/types/phone-number.type'
import API from '@/apis/base'
import { BaseResponse } from '@/types/api.type'
import { authHeader } from '@/constants/auth'

export const sendVerificationCodeToPhoneNumber = (
  params: SendVerificationCodeToPhoneNumberReq,
) => API.post('/api/v1/user/phone-number/sending', params)

/**
 *
 * @description 올바른 핸드폰 번호 인증 코드인지 확인
 * @returns 맞을 경우 `true`, 아닐 경우 `false`
 */
export const verifyPhoneNumberCode = ({ code }: VerifyPhoneNumberCodeReq) =>
  API.get<BaseResponse<boolean>>(`/api/v1/user/phone-number/comparing/${code}`)

export const isPhoneNumberVerified = (accessToken: string) =>
  API.get<BaseResponse<boolean>>('/api/v1/user/phone-number/checking', {
    headers: {
      [authHeader.X_POPUP_AUTH_HEADER]: accessToken,
    },
  })
