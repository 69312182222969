import { phoneNumberAPI, termsAPI } from '@/apis'

export const isSignedUpUser = async ({ accessToken = '' }) => {
  try {
    const phoneCertificated = await phoneNumberAPI
      .isPhoneNumberVerified(accessToken)
      .then(({ data }) => data.data)
    const termsAgreed = await termsAPI
      .getUserAgreementStatus(accessToken)
      .then(({ data }) => data.data.siteTerms && data.data.personalInfoTerms)
    return phoneCertificated && termsAgreed
  } catch (e) {
    return Promise.reject(e)
  }
}
