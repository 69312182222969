import { setAPIAuthHeader } from '@/apis/base'
import { setProxyAPIAuthHeader } from '@/apis/proxy'
import { AuthUser } from '@/types/auth.type'
import { NextRouter } from 'next/router'

export const noop = () => {}

export const goBack = (
  router: NextRouter,
  current: string,
  fallback?: string,
) => {
  router.back()
  if (fallback !== undefined && window.location.pathname.startsWith(current)) {
    router.push(fallback)
  }
}

export const composeTimeSchedule = (from: string, to?: string) => {
  const modAndPad = (num: number, mod: number) =>
    (num % mod).toString().padStart(2, '0')
  const getMeridiem = (hours: number) => (hours >= 12 ? 'PM' : 'AM')

  const [fromHours, fromMinutes] = from.split(':').map(Number)
  const fromStr = `${
    fromHours === 12 ? 12 : modAndPad(fromHours, 12)
  }:${modAndPad(fromMinutes, 60)}${getMeridiem(fromHours)}`

  if (!to) {
    return `${fromStr} ~ `
  }

  const [toHours, toMinutes] = to.split(':').map(Number)
  const toStr = `${toHours === 12 ? 12 : modAndPad(toHours, 12)}:${modAndPad(
    toMinutes,
    60,
  )}${getMeridiem(toHours)}`

  return `${fromStr} - ${toStr}`
}

export const cookieParse = <T extends string>(cookieStr: string) => {
  return cookieStr
    ?.split(';')
    .map((s) => s.trim().split('='))
    .reduce(
      (res, [key, value]) => ({ ...res, [key]: value }),
      {} as Partial<Record<T, string>>,
    )
}

export const setAuthHeader = (authUser: AuthUser) => {
  setAPIAuthHeader(authUser)
  setProxyAPIAuthHeader(authUser)
}
