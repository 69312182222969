import { PATH } from '@/constants/path'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Drawer from '../Drawer'
import { OnobOffIcon, OnobOnIcon } from '../Icons'
import Logo from '../Logo'

interface Props {
  onMenuOpen?: () => void
  onMenuClose?: () => void
  menuButtonVisible?: boolean
}

export default function HomeHeader({
  onMenuClose,
  onMenuOpen,
  menuButtonVisible = true,
}: Props) {
  const router = useRouter()
  const [menuOpened, setMenuOpened] = useState(false)

  const handleMenuClick = () => {
    const newState = !menuOpened
    setMenuOpened(newState)
    if (newState) {
      onMenuOpen?.()
    } else {
      onMenuClose?.()
    }
  }

  const handleGoMain = () => {
    if (router.pathname !== PATH.home) {
      router.push(PATH.home)
    } else {
      router.reload()
    }
  }

  return (
    <header
      className={clsx(
        'inner',
        'fixed',
        'flex',
        'top-0',
        'left-0',
        'right-0',
        'items-center',
        'justify-between',
        'z-10',
        'w-full',
        'h-[72px]',
        'pl-[16px]',
        'pr-[10px]',
        'bg-black',
      )}
    >
      <span
        className={clsx('translate-y-[15%]', 'cursor-pointer')}
        onClick={handleGoMain}
      >
        <Logo />
      </span>
      {menuButtonVisible && (
        <>
          <span onClick={handleMenuClick} className={clsx('cursor-pointer')}>
            {!menuOpened ? <OnobOffIcon /> : <OnobOnIcon />}
          </span>
          <Drawer open={menuOpened} setOpened={setMenuOpened} />
        </>
      )}
    </header>
  )
}
