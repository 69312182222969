import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

import { PATH } from '@/constants/path'
import useAuthStore from '@/stores/useAuthStore'

import InstagramSvg from '@/assets/svgs/instagram.svg'
import { colors } from '@/styles/theme'
import { buttonInfo } from '@/containers/MainCardButton'

interface Props {
  open?: boolean
  setOpened: Dispatch<SetStateAction<boolean>>
}

export default function Drawer({ open, setOpened }: Props) {
  const { removeAuth, isSignedUp } = useAuthStore()

  return (
    <div
      className={clsx(
        'absolute',
        'top-[70px]',
        'right-0',
        'flex',
        'justify-end',
      )}
    >
      {open && (
        <div
          onClick={() => setOpened(false)}
          className={clsx(
            'fixed',
            'top-0',
            'right-0',
            'w-[100vw]',
            'h-[100vh]',
          )}
        />
      )}

      <div
        className={clsx(
          'fixed',
          'top-[70px]',
          'w-[270px]',
          'bg-black',
          'border-l-[1px]',
          'border-l-white',
          'h-[calc(100vh-70px)]',
          open ? 'translate-x-[0%]' : 'translate-x-[100%]',
          'transition-all',
        )}
      >
        <div
          className={clsx(
            'flex',
            'flex-col',
            'py-[34px]',
            'px-[20px]',
            'space-y-[28px]',
            'text-xl',
            'font-[300]',
          )}
        >
          {isSignedUp ? (
            <>
              <Link
                href={''}
                onClick={() => {
                  setOpened(false)
                  removeAuth()
                }}
              >
                로그아웃
              </Link>
              <Link href={PATH.enrollments}>내 마켓</Link>
            </>
          ) : (
            <Link href={PATH.login}>로그인</Link>
          )}
          <div>
            <div className="mb-2">오노브 마켓 신청</div>
            <ol className="ml-5 space-y-2 text-lg">
              <li>
                <Link href={buttonInfo.now2.href}>11/11 헤이이태원</Link>
              </li>
            </ol>
          </div>
          <Link href={PATH.posts}>매거진</Link>
          <Link href="https://instagram.com/onob.kr">
            <InstagramSvg width={24} height={24} fill={colors.white} />
          </Link>
        </div>
      </div>

      <div
        className={clsx(
          'fixed',
          'top-0',
          'w-[270px]',
          'bg-black',
          'h-[100vh]',
          'translate-x-[100%]',
        )}
      ></div>
    </div>
  )
}
