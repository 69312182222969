import API from '@/apis/base'
import {
  BaseResponse,
  IsParticipationEnrolledReq,
  IsParticipationEnrolledRes,
  GetParticipationDetailReq,
  CreateParticipationEnrollmentReq,
  ParticipationEnrollment,
  UploadEnrollmentThumbnailImageReq,
} from '@/types/api.type'

/**
 *
 * @description 플리마켓 신청서 생성
 * @returns `ParticipationEnrollment`
 */
export const createParticipationEnrollment = (
  params: CreateParticipationEnrollmentReq,
) =>
  API.post<BaseResponse<ParticipationEnrollment>>(
    '/api/v1/participation/enrollment',
    params,
  )

/**
 *
 * @description 플리마켓 신청서에 작성한 이미지 업로드
 * @returns uploaded image url
 */
export const uploadEnrollmentThumbnailImage = ({
  file,
  formName,
}: UploadEnrollmentThumbnailImageReq) => {
  const formData = new FormData()
  formData.append(formName, file)

  return API.post<BaseResponse<string>>(
    '/api/v1/participation/enrollment/thumbnail',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

/**
 *
 * @description marketId에 해당하는 플리마켓의 본인 신청 여부 확인
 * @returns 신청했을 경우 `true`, 아닐 경우 `false`
 */
export const isParticipationEnrolled = ({
  marketId,
}: IsParticipationEnrolledReq) =>
  API.get<BaseResponse<IsParticipationEnrolledRes>>(
    `/api/v1/participation/${marketId}/enrolled`,
  )

/**
 *
 * @description marketId 참가 신청 디테일 조회
 * @returns `ParticipationEnrollment`
 */
export const getParticipationEnrollmentDetail = ({
  enrollmentId,
}: GetParticipationDetailReq) =>
  API.get<BaseResponse<ParticipationEnrollment>>(
    `/api/v1/participation/${enrollmentId}`,
  )

/**
 *
 * @description 나의 참가 신청 내역 조회
 * @returns `ParticipationEnrollment[]`
 */
export const getMyParticipationEnrollments = () =>
  API.get<BaseResponse<Array<ParticipationEnrollment>>>(
    '/api/v1/participation/mine',
  )
