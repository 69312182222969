import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { AuthUser } from '@/types/auth.type'

import { setAuthHeader } from '@/utils'
import { isSignedUpUser } from '@/apis/common.api'

import useAuthStore from '@/stores/useAuthStore'
import { PATH } from '@/constants/path'

type Options = {
  signedAccessRequired?: boolean
  loginIfNotSigned?: boolean
}

export default function useAuth(auth?: AuthUser, options?: Options) {
  const [isLoaded, setIsLoaded] = useState(false)
  const signedAccessRequired = !!options?.signedAccessRequired
  const loginIfNotSigned = !!options?.loginIfNotSigned

  const router = useRouter()
  const authStore = useAuthStore()

  useEffect(() => {
    setIsLoaded(false)
    /************* 토큰 없음 *************/
    if (!(auth?.accessToken && auth?.refreshToken)) {
      authStore.removeAuth()

      // 가입 및 동의 필요함
      if (signedAccessRequired && loginIfNotSigned) {
        router.push(PATH.login)
      }

      setIsLoaded(true)
      return
    }

    /************* 토큰 있음 *************/
    // state 설정
    setAuthHeader(auth)
    authStore.setAuth(auth)

    if (!signedAccessRequired || authStore.isSignedUp) {
      setIsLoaded(true)
      return
    }

    // 가입 및 동의 필요함
    isSignedUpUser({ accessToken: auth.accessToken })
      .then((isSignedUp) => {
        if (isSignedUp) {
          authStore.setSignedUp(isSignedUp)
          return
        }

        authStore.removeAuth()
        if (loginIfNotSigned) {
          alert('가입을 완료하면 이용할 수 있어요')
          router.replace(PATH.login)
        }
      })
      .catch(() => {
        authStore.removeAuth()
        if (loginIfNotSigned) {
          router.replace(PATH.login)
        }
      })
      .finally(() => {
        setIsLoaded(true)
      })
  }, [auth?.accessToken, auth?.refreshToken, signedAccessRequired])

  return { isLoaded, ...authStore }
}

export type PropsWithAuth<T = Record<any, any>> = T & {
  auth?: AuthUser
}
