import API from '@/apis/base'
import PROXY_API from '@/apis/proxy'

import type { AuthUser, SignInParams, SignInRes } from '@/types/auth.type'
import { authHeader } from '@/constants/auth'

const PATH = {
  tokenReissue: `/api/v1/user/auth/reissue`,
  signIn: `/api/v1/user/auth/login`,
  signUp: `/api/v1/user/auth/signup`,
}
const proxyUrl = {
  refresh: '/api/auth/refresh',
  login: '/api/auth/login',
  logout: '/api/auth/logout',
}

const reissueAccessToken = ({ refreshToken, accessToken }: AuthUser) => {
  return API.post<{ data: AuthUser }>(PATH.tokenReissue, null, {
    withCredentials: true,
    headers: {
      [authHeader.X_POPUP_AUTH_HEADER]: accessToken,
      Cookie: `${authHeader.X_POPUP_COOKIE}=${refreshToken}`,
    },
  })
}
const proxyReissueAccessToken = () => PROXY_API.post<AuthUser>(proxyUrl.refresh)

const signIn = async (params: SignInParams) =>
  API.post<{ data: SignInRes }>(PATH.signIn, params)

const signUp = (params: SignInParams) =>
  API.post<{ data: SignInRes }>(PATH.signUp, params)

const logOut = () => PROXY_API.post(proxyUrl.logout)

const authAPI = {
  proxyUrl,
  reissueAccessToken: proxyReissueAccessToken,
  logOut,
}

export const realAuthAPI = {
  reissueAccessToken,
  signUp,
  signIn,
}
export default authAPI
